"use strict";

(function($) {
	// Non-post grid.
	$(".column__title").matchHeight();
	$(".column__image").matchHeight();
	$(".column__body").matchHeight();

	// Post grid.
	$(".fl-post-column .fl-post-title").matchHeight();
	$(".fl-post-column .fl-post-image").matchHeight();
	$(".fl-post-column .fl-post-meta").matchHeight();
	$(".fl-post-column .fl-post-excerpt").matchHeight();

	setTimeout(function() {
		if(window.location.pathname === "/propane-safety") {
			if(window.location.hash) {
				$(window.location.hash).trigger("click");
			}
		}
	}, 50);

	$(document).on("click", ".sidebar__contact .fa-comment-alt", function() {
		liveagent.startChat("5730d000000k9bX");
	});

	// FAQ & Propane Safety.
	$(document).on("click", ".faq__tab", function() {
		$(".faq__tab").removeClass("faq__tab--active");
		$(this).addClass("faq__tab--active");
		$(".faq__tab__content").removeClass("faq__tab__content--active");
		$("." + $(this).data("content")).addClass("faq__tab__content--active");
	});

	// Opinion (Tell US How We Are Doing)
	$(document).on("click", ".opinion__tab__tab", function() {
		$(".opinion__tab__tab").removeClass(".opinion__tab__tab--active");
		$(this).addClass(".opinion__tab__tab--active");
		$(".opinion__tab__tab__content").removeClass(".opinion__tab__tab__content--active");
		$("." + $(this).data("content")).addClass(".opinion__tab__tab__content--active");
	});
	// Note: Locations map is in /locations' html module with the map.
})(jQuery);
